<template>
    <b-row class="mt-3">
        <b-col>
            <b-card border-variant="light" class="shadow-sm" header-class="border-0">
                <template #header>
                    <h6 class="mb-0 details-title-value">
                        {{ $t("legal-person.title") }}
                    </h6>
                </template>
                <b-card-text>
                    <b-row v-if="loading" class="justify-content-center my-2">
                        <b-spinner variant="primary"></b-spinner>
                    </b-row>
                    <b-row v-if="!loading" class="text-start my-2">
                        <component v-if="legalEntity" :legalEntity="legalEntity" :is="legalEntityComponent" />
                        <b-col>
                            <div class="text-end">
                                <b-button-group horizontal>
                                    <DisableLegalEntityModal
                                        v-if="legalEntity && (allowFinalBeneficiary || enabledCriteria)"
                                        :id="legalEntity.id" />
                                    <b-button class="main-btn" size="sm" @click="openEdit" v-if="allowFinalBeneficiary || enabledCriteria">
                                        <b-icon-pencil-square />
                                    </b-button>
                                </b-button-group>
                            </div>
                            <div class="text-end" v-if="allowFinalBeneficiary || enabledCriteria">
                                <b-overlay :show="generating" rounded opacity="0.7" spinner-small spinner-variant="primary"
                                    class="d-inline-block mt-4">
                                    <b-button class="main-btn" size="sm" @click="downloadCertificate">{{
                                        $t(
                                            "legal-person.request-register-certificate"
                                        )
                                    }}</b-button>
                                </b-overlay>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-col>
        <UpdateLegalEntityModal :isOpen="isOpenModal" :legalEntity="legalEntity" @close="closeEdit" />
    </b-row>
</template>

<script>
import axios from "axios";
import { RepositoryFactory } from "@/services/api";
import { mapActions, mapGetters } from "vuex";

import NotCarryOutOperationsInPanamaLegalPerson from "./legal-entity-type/NotCarryOutOperationsInPanamaLegalPerson.vue";
import CarryOutOperationsInPanamaLegalPerson from "./legal-entity-type/CarryOutOperationsInPanamaLegalPerson.vue";
import ListedOnStockExchangeLegalPerson from "./legal-entity-type/ListedOnStockExchangeLegalPerson.vue";
import StateOrMultilateralEntity from "./legal-entity-type/StateOrMultilateralEntity.vue";
import OwnedStateByLegalPerson from "./legal-entity-type/OwnedStateByLegalPerson.vue";
import MixedOperationsLegalEntity from "./legal-entity-type/MixedOperationsLegalEntity.vue";

import UpdateLegalEntityModal from "./UpdateLegalEntityModal.vue";
import DisableLegalEntityModal from "./DisableLegalEntityModal.vue";

const apiUrl = process.env.VUE_APP_BACKEND_API_URL;

export default {
    name: "legal-person",
    components: {
        UpdateLegalEntityModal,
        DisableLegalEntityModal,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            legalEntity: null,
            generating: false,

            isOpenModal: false,
        };
    },
    async mounted() {
        await this.retrieveLegalEntity();
    },
    computed: {
        ...mapGetters("oidcStore", ["oidcAccessToken"]),
        ...mapGetters("application", [
            "allowResidentAgentAdminFinalBeneficiaries",
            "getEnabledCriteria",
        ]),
        allowFinalBeneficiary() {
            return this.allowResidentAgentAdminFinalBeneficiaries;
        },
        enabledCriteria() {
            return this.getEnabledCriteria;
        },
        legalEntityComponent() {
            if (this.legalEntity === null) {
                return null;
            }
            switch (this.legalEntity.legalEntityType) {
                case 0:
                    return NotCarryOutOperationsInPanamaLegalPerson;
                case 1:
                    return CarryOutOperationsInPanamaLegalPerson;
                case 2:
                    return ListedOnStockExchangeLegalPerson;
                case 3:
                    return StateOrMultilateralEntity;
                case 4:
                    return OwnedStateByLegalPerson;
                case 5:
                    return MixedOperationsLegalEntity;
            }
            return null;
        }
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async retrieveLegalEntity() {
            this.loading = true;
            try {
                let response = await RepositoryFactory.legalEntity.get({
                    id: this.id,
                });
                let legalEntity = response.data;
                if (legalEntity.enabled === true || this.enabledCriteria) {
                    this.legalEntity = legalEntity;
                    this.$emit("loaded", legalEntity);
                } else {
                    this.setModalInfo({
                        title: "Persona jurídica desactivada",
                        message:
                            "La persona jurídica a la que quiere acceder esta desactivada y por lo tanto no se puede mostrar su información",
                        routeName: "legal-entities",
                    });
                    this.openModal();
                }
            } catch (error) {
                window.console.log(error);
            }
            this.loading = false;
        },
        downloadCertificate() {
            try {
                this.generating = true;
                // Pending to refactor and do it inside repository
                axios({
                    url: `${apiUrl}api/legalEntity/${this.id}/certificate`,
                    method: "GET",
                    responseType: "blob",
                    headers: {
                        authorization: `Bearer ${this.oidcAccessToken}`,
                    },
                }).then((response) => {
                    window.console.log(response.data);
                    var fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    var fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", "Legal Entity.pdf");
                    document.body.appendChild(fileLink);

                    fileLink.click();
                });
            } catch (error) {
                window.console.log(error);
            } finally {
                this.generating = false;
            }
        },
        openEdit() {
            this.isOpenModal = true;
        },
        closeEdit(legalEntity) {
            if (legalEntity != null) {
                this.legalEntity = legalEntity;
                this.$emit("refresh", this.legalEntity);
            }
            this.isOpenModal = false;
        },
    },
};
</script>