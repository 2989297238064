<template>
    <select
        class="form-select form-select-sm rounded-0"
        v-model="reason"
        @change="change"
    >
        <option :value="null"></option>
        <option v-for="reason in reasons" :key="reason.key" :value="reason.key">
            {{ $t(`disable-reasons.${reason.label}`) }}
        </option>
    </select>
</template>

<script>
import { reasons } from "@/config/disable-reasons";

export default {
    name: "disable-reason-select",
    props: {
        value: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            reason: null,
            reasons: [],
        };
    },
    mounted() {
        this.reasons = reasons;
        this.reason = this.value;
    },
    methods: {
        change() {
            this.$emit("input", this.reason);
        },
    },
};
</script>