<template>
    <div class="mt-2">
        <b-form-group class="mt-3">
            <label class="form-title">Primer Nombre*</label>
            <b-icon-question-circle-fill class="ms-2 card-title-color"
                title="Como está en Número de cédula, pasaporte o documento de identidad personal (formato TE)" />
            <b-form-input v-model="finalBeneficiaryForm.name" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Segundo Nombre</label>
            <b-icon-question-circle-fill class="ms-2 card-title-color"
                title="Como está en Número de cédula, pasaporte o documento de identidad personal (formato TE)" />
            <b-form-input v-model="finalBeneficiaryForm.secondName" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Primer Apellido*</label>
            <b-icon-question-circle-fill class="ms-2 card-title-color"
                title="Como está en Número de cédula, pasaporte o documento de identidad personal (formato TE)" />
            <b-form-input v-model="finalBeneficiaryForm.surnames" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Segundo Apellido</label>
            <b-icon-question-circle-fill class="ms-2 card-title-color"
                title="Como está en Número de cédula, pasaporte o documento de identidad personal (formato TE)" />
            <b-form-input v-model="finalBeneficiaryForm.secondSurname" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">
                Número de cédula, pasaporte o documento de identidad personal
                (formato TE)*
            </label>
            <b-form-input v-model="finalBeneficiaryForm.cedula" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Fecha de nacimiento*</label>
            <DatePicker v-model="finalBeneficiaryForm.birthDate" />
        </b-form-group>

        <b-form-group class="mt-3">
            <label class="form-title">Nacionalidad*</label>
            <select class="form-select form-select-sm rounded-0" v-model="finalBeneficiaryForm.nationality">
                <option :value="null"></option>
                <option v-for="country in countries" :key="country" :value="country">
                    {{ country }}
                </option>
            </select>
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Dirección*</label>
            <b-form-input v-model="finalBeneficiaryForm.direction" size="sm" required class="rounded-0" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">
                Fecha en la que se adquiere la condición de beneficiario final
                de la persona jurídica*
            </label>
            <DatePicker v-model="finalBeneficiaryForm.statusAcquireDate" />
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">Email</label>
            <b-form-input placeholder="xxx@xxx.com" v-model="finalBeneficiaryForm.email" size="sm" class="rounded-0" />
        </b-form-group>

        <PhoneField ref="phoneField" v-model="finalBeneficiaryForm.phone" />

        <ReasonGetFinalBeneficiaryStatusSelect ref="benefSelect"
            v-model="finalBeneficiaryForm.reasonGetFinalBeneficiaryStatus" />

        <b-overlay :show="sending" rounded opacity="0.7" spinner-small spinner-variant="primary" class="d-inline-block">
            <b-button class="my-4 main-btn" @click="addFinalBeneficiary" :disabled="!canSave">
                <span v-if="finalBeneficiaryEdit != null">Editar Beneficiario Final</span>
                <span v-else>Agregar Beneficiario Final</span>
            </b-button>
        </b-overlay>
    </div>
</template>

<script>
import moment from "moment";
import { countries } from "@/config/countries";
import PhoneField from "@/components/external-components/PhoneField.vue";
import DatePicker from "@/components/widgets/DatePicker.vue";
import ReasonGetFinalBeneficiaryStatusSelect from "@/components/widgets/ReasonGetFinalBeneficiaryStatusSelect.vue";
import { RepositoryFactory } from "@/services/api";

export default {
    name: "update-final-beneficiary-operation-form",
    components: {
        PhoneField,
        DatePicker,
        ReasonGetFinalBeneficiaryStatusSelect,
    },
    props: {
        legalEntityId: {
            type: String,
            required: true,
        },
        finalBeneficiaryWho: {
            type: Number,
            required: true
        },
        finalBeneficiaryEdit: {
            type: Object,
            default: null,
        },
    },
    mounted() {
        let item = this.finalBeneficiaryEdit;
        if (item) {
            this.notifyUpdateFinalBeneficiary(item);
        }
    },
    data() {
        return {
            countries: countries,
            finalBeneficiaryIndex: null,
            finalBeneficiaryForm: {
                name: "",
                surnames: "",
                secondName: "",
                secondSurname: "",
                cedula: "",
                birthDate: null,
                nationality: "",
                direction: "",
                email: "",
                phone: "",
                statusAcquireDate: null,
                reasonGetFinalBeneficiaryStatus: { others: "", status: null },
                finalBeneficiaryType: 1,
                finalBeneficiaryWho: 0
            },
            finalBeneficiaries: [],
            sending: false
        };
    },
    computed: {
        canSave() {
            const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            let form = this.finalBeneficiaryForm;
            var isEmail = form.email && form.email.trim() !== '' ? regex.test(form.email) : true;
            let valid =
                form.name !== "" &&
                form.surnames !== "" &&
                form.cedula !== "" &&
                form.birthDate !== null &&
                form.nationality !== "" &&
                isEmail &&
                form.direction !== "" &&
                form.reasonGetFinalBeneficiaryStatus !== null &&
                form.reasonGetFinalBeneficiaryStatus.status !== null &&
                form.statusAcquireDate !== "" &&
                form.statusAcquireDate !== null;

            return valid;
        },
    },
    methods: {
        async addFinalBeneficiary() {
            try {
                this.sending = true;
                let finalBeneficiary = { ...this.finalBeneficiaryForm };
                finalBeneficiary.finalBeneficiaryWho = this.finalBeneficiaryWho;

                let payload = {
                    legalEntityId: this.legalEntityId,
                    finalBeneficiary: finalBeneficiary,
                };

                if (this.finalBeneficiaryEdit != null) {
                    payload.finalBeneficiaryId = this.finalBeneficiaryEdit.id;
                    await RepositoryFactory.legalEntity.updateFinalBeneficiary(
                        payload, this.finalBeneficiaryEdit.id
                    );
                } else {
                    await RepositoryFactory.legalEntity.addFinalBeneficiary(
                        payload
                    );
                }

                this.$emit("success");
            } catch (error) {
                window.console.log(error);
                this.$emit("error", error);
            }
            finally {
                this.sending = false;
            }
        },
        notifyUpdateFinalBeneficiary(item) {
            this.finalBeneficiaryForm = {
                name: item.name,
                surnames: item.surnames,
                secondName: item.secondName,
                secondSurname: item.secondSurname,
                cedula: item.cedula,
                birthDate: item.birthDate ? this.parseDate(item.birthDate) : null,
                nationality: item.nationality,
                direction: item.direction,
                email: item.email,
                phone: item.phone,
                statusAcquireDate: item.statusAcquireDate ? this.parseDate(item.statusAcquireDate) : null,
                reasonGetFinalBeneficiaryStatus: {
                    others: item.reasonGetFinalBeneficiaryStatus === null ? "" : item.reasonGetFinalBeneficiaryStatus.others
                    , status: item.reasonGetFinalBeneficiaryStatus === null ? "" : item.reasonGetFinalBeneficiaryStatus.status
                },
                finalBeneficiaryType: item.finalBeneficiaryType,
                finalBeneficiaryWho: item.finalBeneficiaryWho
            };
            this.$refs.phoneField.loadPhone(item.phone);

            if (item.reasonGetFinalBeneficiaryStatus !== null)
                this.$refs.benefSelect.load(item.reasonGetFinalBeneficiaryStatus.status, item.reasonGetFinalBeneficiaryStatus.others);
        },
        deleteFinalBeneficiary(index) {
            this.finalBeneficiaries.splice(index, 1);
            if (this.finalBeneficiaryIndex === index) {
                this.finalBeneficiaryIndex = null;
            }
        },
        clearFields() {
            this.finalBeneficiaryForm = {
                name: "",
                surnames: "",
                secondName: "",
                secondSurname: "",
                cedula: "",
                birthDate: null,
                nationality: "",
                direction: "",
                statusAcquireDate: null,
                phone: "",
                email: "",
                reasonGetFinalBeneficiaryStatus: { others: "", status: null },
                finalBeneficiaryType: 1,
                finalBeneficiaryWho: 0
            };
            this.$refs.phoneField.clear();
            this.$refs.benefSelect.clear();
        },
        parseDate(date) {
            return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
        },
    },
};
</script>

<style scoped>
.toggle-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
</style>