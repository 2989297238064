<template>
    <b-modal v-model="isOpen" content-class="rounded-0" dialog-class="long-modal" header-class="border-0"
        header-bg-variant="light" hide-header-close centered no-close-on-backdrop no-close-on-esc hide-footer>
        <template #modal-title> {{ textLegalEntityType(legalEntity.legalEntityType) }} </template>
        <div v-if="legalEntity !== null">
            <component :is="legalEntityComponent" v-model="legalPerson" :legalEntityType="legalEntity.legalEntityType" />
        </div>
        <b-row class="m-3 text-end">
            <b-col>
                <b-button size="sm" variant="danger" class="px-3 ms-4" @click="close(null)">
                    Cerrar
                </b-button>
                <b-button size="sm" class="main-btn px-3 ms-4" @click="save">
                    Aceptar
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { RepositoryFactory } from "@/services/api";

import UpdateLegalPersonOperationForm from "../legal-entity-update/UpdateLegalPersonOperationForm.vue";
import UpdateLegalPersonListedForm from "../legal-entity-update/UpdateLegalPersonListedForm";
import UpdateLegalPersonStateForm from "../legal-entity-update/UpdateLegalPersonStateForm.vue";
import UpdateLegalPersonPropertyForm from "../legal-entity-update/UpdateLegalPersonPropertyForm.vue";

export default {
    name: "update-legal-entity-modal",
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        legalEntity: {
            type: Object,
        },
    },
    data() {
        return {
            legalPerson: null,
        };
    },
    mounted() {
        this.legalPerson = this.legalEntity;
    },
    computed: {
        legalEntityComponent() {
            switch (this.legalEntity?.legalEntityType) {
                case 0:
                    return UpdateLegalPersonOperationForm;
                case 1:
                    return UpdateLegalPersonOperationForm;
                case 2:
                    return UpdateLegalPersonListedForm;
                case 3:
                    return UpdateLegalPersonStateForm;
                case 4:
                    return UpdateLegalPersonPropertyForm;
                case 5:
                    return UpdateLegalPersonOperationForm;
            }

            return null;
        },
    },
    watch: {
        legalEntity() {
            this.legalPerson = this.legalEntity;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async save() {
            try {
                let legalEntity = this.legalPerson;
                legalEntity.legalEntityType = this.legalEntity.legalEntityType;
                let payload = {
                    id: this.legalPerson.id,
                    legalEntity: legalEntity,
                };
                await RepositoryFactory.legalEntity.update(payload);

                this.setModalInfo({
                    title: "Persona jurídica actualizada correctamente",
                    message: "Se han actualizado correctamente los datos de la persona jurídica",
                    subMessage: "",
                });
                this.close(legalEntity);
            } catch (error) {
                let status = error.response?.status;
                let response = error.response?.data;

                if (status === 400 && typeof response === "object") {
                    let message = "";
                    if (response?.title === "One or more validation errors occurred." && response?.errors) {
                        var keyValidation = Object.keys(response.errors)[0];
                        if (keyValidation.includes("enrollmentDate")) {
                            message = "Fecha de inscripción es requerido.";
                        } else {
                            message = "Error al consumir servicio.";
                        }
                    } else {
                        response.forEach((item) => {                         
                            let msg = this.$t(item.value);
                            message += `<p>${msg}</p>`;
                        });
                    }
                    this.setModalInfo({
                        title: "Aviso",
                        message: message,
                    });
                } else {
                    this.setModalInfo({
                        title: "Error actualizando persona jurídica",
                        message: "Se ha producido un error intentando actualizar los datos de la persona jurídica",
                        subMessage: "Por favor, revise que ha introducido datos correctos y vuelva a intentarlo",
                    });
                }
            }
            this.openModal();
        },
        close(legalEntity) {
            this.$emit("close", legalEntity);
        },
        textLegalEntityType(type) {
            switch (type) {
                case 0:
                    return "Persona Jurídica con operaciones y/o activos fuera de Panamá";
                case 1:
                    return "Persona Jurídica con operaciones y/o activos en Panamá";
                case 2:
                    return "Persona jurídica listada en Bolsa de Valores";
                case 3:
                    return "Persona jurídica propiedad de una Entidad Estatal o Multilateral";
                case 4:
                    return "Persona jurídica propiedad de un Estado";
                case 5:
                    return "Persona jurídica con operaciones y/o activos en varias jurisdicciones incluyendo Panamá";
                default:
                    return "Persona Jurídica";
            }
        }
    },
};
</script>