<template>
    <b-row class="mt-2">
        <b-col>
            <b-form-group>
                <label class="form-title">
                    {{ $t("general.society-type") }}
                </label>
                <select disabled class="form-select form-select-sm rounded-0" v-model="legalPersonForm.societyType">
                    <option value="SA">
                        {{ $t("society-types.sociedad anonima") }}
                    </option>
                    <option value="FU">
                        {{ $t("society-types.fundacion de interes propio") }}
                    </option>
                    <option value="LI">
                        {{ $t("society-types.sociedad limitada") }}
                    </option>
                    <option value="EX">
                        {{ $t("society-types.sociedad extranjera") }}
                    </option>
                    <option value="CM">
                        {{ $t("society-types.sociedad en comandita") }}
                    </option>
                    <option value="S.EP">
                        {{ $t("society-types.sociedad de emprendimiento") }}
                    </option>
                </select>
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title">Número de ficha o folio*</label>
                <b-form-input v-model="legalPersonForm.folioNumber" size="sm" required class="rounded-0" disabled />
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title">Nombre completo*</label>
                <b-icon-question-circle-fill class="ms-2 card-title-color"
                    title="Como aparece en el Registro Público" />
                <b-form-input v-model="legalPersonForm.name" size="sm" required class="rounded-0" />
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title">RUC*</label>
                <b-form-input v-model="legalPersonForm.ruc" size="sm" required class="rounded-0" />
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title">Fecha de inscripción*</label>
                <DatePicker v-model="legalPersonForm.enrollmentDate" />
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title">Dirección*</label>
                <b-form-input v-model="legalPersonForm.direction" size="sm" required class="rounded-0" />
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title">Actividad principal*</label>
                <PrincipalActivitySelect v-model="legalPersonForm.principalActivity" />
            </b-form-group>
            <b-row v-if="legalEntityType != 1">
                <b-col cols="5">
                    <b-form-group class="mt-3">
                        <label class="form-title">
                            Jurisdicción *
                        </label>
                        <select class="form-select form-select-sm rounded-0" v-model="jurisdictionselect">
                            <option value=" ">

                            </option>
                            <option v-for="country in countries" :key="country" :value="country">
                                {{ country }}
                            </option>
                        </select>
                    </b-form-group>
                </b-col>
                <b-col cols="5"> <b-form-group class="mt-3">
                        <label class="form-title">NIT*</label>
                        <b-form-input v-model="nit" size="sm" required class="rounded-0" />
                    </b-form-group>
                </b-col>
                <b-col cols="2">
                    <b-button style="margin-top: 40%" v-b-tooltip.hover title="Salvar" @click="addJuridiction"
                        class="main-btn ">
                        <b-icon-plus scale="1.5" />
                    </b-button>
                </b-col>
            </b-row>
            <listJuridictions v-if="legalEntityType != 1" :jurisdictions="legalPersonForm.jurisdictions"
                @delete="deleteJuridiction" />

            <label class="form-title mt-3">
                Servicio de accionistas / directores nominales o apoderados
            </label>
            <b-td style="border: 1px"></b-td>
            <b-row>
                <b-col cols="9">
                    <b-form-group>
                        <label class="form-title">
                            ¿Servicio de accionista Nominal?
                        </label>
                    </b-form-group>
                    <b-form-group>
                        <label class="form-title">
                            ¿Servicio de director Nominal?
                        </label>
                    </b-form-group>
                    <b-form-group>
                        <label class="form-title">
                            ¿Servicio de apoderado?
                        </label>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-row>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.nominalShareholderService"
                                    :aria-describedby="ariaDescribedby" name="radio1" :value="true"> Si</b-form-radio>
                            </b-col>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.nominalShareholderService"
                                    :aria-describedby="ariaDescribedby" name="radio1" :value="false"> No</b-form-radio>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-row>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.nominalDirectorService"
                                    :aria-describedby="ariaDescribedby" name="radio2" :value="true"> Si</b-form-radio>
                            </b-col>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.nominalDirectorService"
                                    :aria-describedby="ariaDescribedby" name="radio2" :value="false"> No</b-form-radio>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-row>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.proxyService" :aria-describedby="ariaDescribedby"
                                    name="radio3" :value="true"> Si</b-form-radio>
                            </b-col>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.proxyService" :aria-describedby="ariaDescribedby"
                                    name="radio3" :value="false"> No</b-form-radio>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import { countries } from "@/config/countries";
import DatePicker from "@/components/widgets/DatePicker.vue";
import PrincipalActivitySelect from "@/components/widgets/PrincipalActivitySelect.vue";
import listJuridictions from "@/components/legal-entities-types/list/ListJuridictions.vue";
import { parseSocietyType } from "@/utils/parse";

export default {
    name: "update-legal-person-operation-form",
    components: {
        DatePicker,
        PrincipalActivitySelect,
        listJuridictions
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        legalEntityType: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            countries: countries,
            jurisdictionselect: null,
            nit: null,
            legalPersonForm: {},
        };
    },
    mounted() {
        let legalEntity = JSON.parse(JSON.stringify(this.value));
        this.legalPersonForm = {
            id: legalEntity.id,
            name: legalEntity.name,
            ruc: legalEntity.ruc,
            folioNumber: legalEntity.folioNumber,
            enrollmentDate: legalEntity.enrollmentDate,
            direction: legalEntity.direction,
            principalActivity: legalEntity.principalActivity,
            societyType: parseSocietyType(legalEntity.societyType),
            shareholder: legalEntity.shareholder,
            noAssets: legalEntity.noAssets,
            jurisdictions: legalEntity.jurisdictions,
            nominalShareholderService: legalEntity.nominalShareholderService,
            nominalDirectorService: legalEntity.nominalDirectorService,
            proxyService: legalEntity.proxyService,
        };
    },
    computed: {
        showNoAssets() {
            return this.legalEntityType === 1;
        },
    },
    watch: {
        legalPersonForm: {
            deep: true,
            handler() {
                this.$emit("input", this.legalPersonForm);
            },
        },
    },
    methods: {
        changeShareholder() {
            if (this.showNoAssets && this.legalPersonForm.shareholder) {
                this.legalPersonForm.noAssets = false;
            }
        },
        changeAssets() {
            if (this.showNoAssets && this.legalPersonForm.noAssets) {
                this.legalPersonForm.shareholder = false;
            }
        },
        addJuridiction() {
            if ((typeof this.jurisdictionselect != "undefined" && this.jurisdictionselect) && (typeof this.nit != "undefined" && this.nit)) {
                let juridictObj = { jurisdiction: this.jurisdictionselect, nit: this.nit };
                this.legalPersonForm.jurisdictions.push(juridictObj);
                this.jurisdictionselect = " ";
                this.nit = null;
            }
        },
        deleteJuridiction(index) {
            this.legalPersonForm.jurisdictions.splice(index, 1);
            if (this.juridictionIndex === index) {
                this.juridictionIndex = null;
            }
        },
    },
};
</script>