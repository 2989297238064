<template>
    <b-card no-header no-body class="rounded-0 shadow-sm border-0">
        <b-tabs class="mt-3">
            <b-tab title="Persona Jurídica que cotiza en bolsa" active
                v-if="items.filter(n => n.finalBeneficiaryWho === 1).length > 0">
                <OtherTypeFinalBeneficiaries :loading="loading" :items="items.filter(n => n.finalBeneficiaryWho === 1)"
                    :finalBeneficiaryWho="1" @delete="removeFinalBenefiary" @update="updateFinalBenefiary" />
            </b-tab>
            <b-tab title="Estado" active v-if="items.filter(n => n.finalBeneficiaryWho === 2).length > 0">
                <OtherTypeFinalBeneficiaries :loading="loading" :items="items.filter(n => n.finalBeneficiaryWho === 2)"
                    :finalBeneficiaryWho="2" @delete="removeFinalBenefiary" @update="updateFinalBenefiary" />
            </b-tab>
            <b-tab title="Entidad Estatal o Multilateral" active
                v-if="items.filter(n => n.finalBeneficiaryWho === 3).length > 0">
                <OtherTypeFinalBeneficiaries :loading="loading" :items="items.filter(n => n.finalBeneficiaryWho === 3)"
                    :finalBeneficiaryWho="3" @delete="removeFinalBenefiary" @update="updateFinalBenefiary" />
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";

import OtherTypeFinalBeneficiaries from "./who/OtherTypeFinalBeneficiaries.vue";

export default {
    name: "simple-final-beneficiaries",
    components: {
        OtherTypeFinalBeneficiaries
    },
    props: {
        legalEntityId: {
            type: String,
            required: true,
        },
        allowDelete: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            loading: false,
            items: [],
        };
    },
    async mounted() {
        await this.retrieveFinalBeneficiaries();
    },
    methods: {
        refreshData() {
            this.retrieveFinalBeneficiaries();
        },
        async retrieveFinalBeneficiaries() {
            this.loading = true;
            try {
                let response =
                    await RepositoryFactory.legalEntity.getFinalBeneficiaries({
                        id: this.legalEntityId,
                    });
                console.log(response.data)
                let items = response.data;

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    item.creationDate = this.parseAndConvertDate(
                        item.creationDate
                    );
                    item.statusAcquireDate = this.parseDate(
                        item.statusAcquireDate
                    );
                    item.birthDate = this.parseDate(item.birthDate);
                    item.email = item.email ?? "-";
                    item.phone = item.phone ?? "-";
                }
                this.items = items;
            } catch (error) {
                window.console.log(error);
            }
            this.loading = false;
        },
        parseDate(date) {
            if (date === null) {
                return "-";
            }
            return moment(date).format("DD/MM/YYYY");
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        updateFinalBenefiary(item) {
            this.$emit("update", item);
        },
        removeFinalBenefiary(id) {
            this.$emit("delete", id);
        },
    },
};
</script>