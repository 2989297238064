<template>
    <div class="mt-5 px-xl-5">
        <h6 class="page-title pb-2">
            <b-button class="main-btn rounded" @click="goToLegalEntities">
                <b-icon icon="arrow-left" />
            </b-button>
            DATOS DE LA PERSONA JURÍDICA
        </h6>
        <LegalPerson :id="id" @loaded="loaded" @refresh="refresh" />
        <b-tabs v-if="showBeneficiaries" class="mt-3">
            <b-tab title="Beneficiarios Finales" active>
                <FinalBeneficiaryList :legalEntity="legalEntity" />
            </b-tab>
            <b-tab title="Jurisdicciones" v-if="legalEntity && legalEntity.legalEntityType != 1">
                <JurisdictionsList :legalEntity="legalEntity" :noColumnNit="false" ref="JurisdictionsList" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import LegalPerson from "./LegalPerson.vue";
import FinalBeneficiaryList from "./FinalBeneficiaryList.vue";
import { mapGetters } from "vuex";
import JurisdictionsList from "./JurisdictionsList.vue";


export default {
    name: "legal-entity-details",
    components: {
        LegalPerson,
        FinalBeneficiaryList,
        JurisdictionsList
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            legalEntity: null,
        };
    },
    computed: {
        ...mapGetters("application", ["getEnabledCriteria"]),
        showBeneficiaries() {
            if (this.legalEntity === null) {
                return false;
            }
            if (this.legalEntity.enabled === false && this.getEnabledCriteria !== true) {
                return false;
            }
            return true;
        },
    },
    methods: {
        loaded(legalEntity) {
            this.legalEntity = legalEntity;
        },
        refresh(legalEntity) {
            this.legalEntity = legalEntity;
            this.$refs.JurisdictionsList.refresh(this.legalEntity);
        },
        goToLegalEntities() {
            this.$router.push({
                name: "legal-entities",
            });
        },
    },
};
</script>