<template>
    <select
        class="form-select form-select-sm rounded-0"
        v-model="reason"
        @change="change"
    >
        <option :value="null"></option>
        <option v-for="(reason, r) in reasons" :key="r">
            {{ reason.reason }}
        </option>
    </select>
</template>

<script>
import { RepositoryFactory } from "@/services/api";

export default {
    name: "other-reason-disable-select",
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            reason: null,
            reasons: [],
        };
    },
    async mounted() {
        this.reasons = await this.getReasons();
        this.reason = this.value;
    },
    methods: {
        async getReasons() {
            let response =
                await RepositoryFactory.legalEntity.getReasonDisableAll();
            return response.data;
        },
        change() {
            this.$emit("input", this.reason);
        },
    },
};
</script>
