<template>
    <b-card no-header no-body class="rounded-0 shadow-sm border-0">
        <b-table
            small
            stacked="lg"
            :busy.sync="loading"
            :items="items"
            :fields="columns"
            show-empty
            empty-text="No hay beneficiarios finales que mostrar"
            responsive
        >
            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                </div>
                <div class="text-center text-primary my-2">
                    <strong>Cargando beneficiarios finales</strong>
                </div>
            </template>
            <template #cell(reason)="data">
                <b-icon-card-list
                    style="cursor: pointer"
                    v-b-tooltip.hover.left
                    variant="primary"
                    :title="getReason(data.item.reasonGetFinalBeneficiaryStatus)"
                />
            </template>
            <template #cell(action)="data">
                <div class="d-flex justify-content-center">
                    <b-button class="me-2" @click="editFinalBenefiary(data.item)" variant="success">
                        <b-icon icon="pencil" v-b-tooltip.hover title="Editar" />
                    </b-button>
                    <b-button @click="removeFinalBenefiary(data.item)" variant="danger" v-if="allowDelete">
                        <b-icon icon="x-circle" v-b-tooltip.hover title="Borrar" />
                    </b-button>
                </div>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { reasons } from "@/config/final-beneficiary-reasons";
export default {
    name: "natural-person-final-beneficiaries",
    props: {
        items: [],
        allowDelete: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            columns: [
                { key: "name", label: "Primer Nombre" },
                { key: "secondName", label: "Segundo Nombre" },
                { key: "surnames", label: "Primer Apellido" },
                { key: "secondSurname", label: "Segundo Apellido" },
                { key: "cedula", label: "Cédula" },
                { key: "birthDate", label: "Fecha de nacimiento" },
                { key: "nationality", label: "Nacionalidad" },
                { key: "direction", label: "Dirección" },
                { key: "statusAcquireDate", label: "Fecha de adquisición" },
                { key: "email", label: "Correo electrónico" },
                { key: "phone", label: "Teléfono" },
                { key: "reason", label: "Motivo", class: "text-center" },
                { key: "creationDate", label: "Fecha de creación" },
                { key: "action", label: "", class: "text-center" },
            ],
        };
    },
    methods: {
        getReason(item) {
            if (item === null || item === undefined) {
                return "-";
            }
            let status = item.status;
            if (status === -1) {
                return "Otros: " + item.others;
            }
            let reason = reasons.find((r) => r.key === status);
            if (reason === undefined) {
                return "-";
            }
            return reason.label;
        },
        editFinalBenefiary(item) {
            this.$emit("update", item);
        },
        removeFinalBenefiary(item) {
            this.$emit("delete", item.id);
        },
    },
};
</script>