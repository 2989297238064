<template>
    <b-card no-header no-body class="rounded-0 shadow-sm border-0">
        <b-table
            small
            stacked="lg"
            :busy.sync="loading"
            :items="items"
            :fields="columns"
            show-empty
            empty-text="No hay beneficiarios finales que mostrar"
            responsive
        >
            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                </div>
                <div class="text-center text-primary my-2">
                    <strong>Cargando beneficiarios finales</strong>
                </div>
            </template>
            <template #cell(reason)="data">
                <b-icon-card-list
                    style="cursor: pointer"
                    v-b-tooltip.hover.left
                    variant="primary"
                    :title="getReason(data.item.reasonGetFinalBeneficiaryStatus)"
                />
            </template>
            <template #cell(constitutionDate)="data">
                {{ parseDate(data.item.constitutionDate) }}
            </template>
            <template #cell(acquisitionDate)="data">
                {{ parseDate(data.item.acquisitionDate) }}
            </template>
            <template #cell(action)="data">
                <div class="d-flex justify-content-center">
                    <b-button class="me-2" @click="editFinalBenefiary(data.item)" variant="success">
                        <b-icon icon="pencil" v-b-tooltip.hover title="Editar" />
                    </b-button>
                    <b-button @click="removeFinalBenefiary(data.item)" variant="danger" v-if="allowDelete">
                        <b-icon icon="x-circle" v-b-tooltip.hover title="Borrar" />
                    </b-button>
                </div>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { reasons } from "@/config/final-beneficiary-reasons";
import moment from 'moment';

export default {
    name: "other-final-beneficiaries",
    props: {
        items: [],
        finalBeneficiaryWho: {
            type: Number,
            required: true
        },
        allowDelete: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.setWho();
    },
    data() {
        return {
            columns: [],
        };
    },
    watch: {
        items() {
            this.setWho();
        }
    },
    methods: {
        getReason(item) {
            if (item === null || item === undefined) {
                return "-";
            }
            let status = item.status;
            if (status === -1) {
                return "Otros: " + item.others;
            }
            let reason = reasons.find((r) => r.key === status);
            if (reason === undefined) {
                return "-";
            }
            return reason.label;
        },
        editFinalBenefiary(item) {
            this.$emit("update", item);
        },
        removeFinalBenefiary(item) {
            this.$emit("delete", item.id);
        },
        parseDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        setWho() {
            if (this.finalBeneficiaryWho === 1) {
                this.columns = [
                    { key: "entityCompleteName", label: "Nombre" },
                    { key: "direction", label: "Dirección" },
                    { key: "country", label: "País" },
                    { key: "stockExchangeCompleteName", label: "Bolsa" },
                    { key: "email", label: "Coreo Electrónico" },
                    { key: "phone", label: "Teléfono" },
                    { key: "jurisdictionStockExchangeWhereListed", label: "Jurisdicción" },
                    { key: "legalRepresentantCompleteName", label: "Representante" },
                    { key: "reason", label: "Motivo", class: "text-center" },
                    { key: "action", label: "", class: "text-center" },
                ];
            }
            if (this.finalBeneficiaryWho === 2) {
                this.columns = [
                    { key: "country", label: "País" },
                    { key: "constitutionDate", label: "Fecha de constitución" },
                    { key: "acquisitionDate", label: "Fecha de adquisición" },
                    { key: "entityCompleteName", label: "Nombre" },
                    { key: "email", label: "Coreo Electrónico" },
                    { key: "phone", label: "Teléfono" },
                    { key: "reason", label: "Motivo", class: "text-center" },
                    { key: "action", label: "", class: "text-center" },
                ];
            }
            if (this.finalBeneficiaryWho === 3) {
                this.columns = [
                    { key: "entityCompleteName", label: "Nombre" },
                    { key: "direction", label: "Dirección" },
                    { key: "country", label: "País" },
                    { key: "legalRepresentantCompleteName", label: "Representante legal" },
                    { key: "email", label: "Coreo Electrónico" },
                    { key: "phone", label: "Teléfono" },
                    { key: "reason", label: "Motivo", class: "text-center" },
                    { key: "action", label: "", class: "text-center" },
                ];
            }
        }
    },
};
</script>