<template>
    <div>
        <b-modal v-model="isOpen" content-class="rounded-0" dialog-class="long-modal" header-class="border-0"
            header-bg-variant="light" centered no-close-on-backdrop hide-footer>
            <template #modal-header>
                <div class="modal-custom-title">
                    <h5 class="modal-title">
                        <span v-if="finalBeneficiaryEdit != null">{{ $t("add-final-beneficiary-modal.title-edit")
                            }}</span>
                        <span v-else>{{ $t("add-final-beneficiary-modal.title") }}</span>
                    </h5>
                    <b-button size="sm" class="close-modal-button" variant="light" @click="close">
                        <b-icon-x scale="1.5"></b-icon-x>
                    </b-button>
                </div>
            </template>
            <div>
                <b-row v-if="isError" class="p-2">
                    <b-col class="text-center">
                        <b-icon-x-circle scale="2" variant="danger" />
                        <h6 class="mt-3">
                            {{ errorMessage }}
                        </h6>
                        <b-button class="main-btn mt-2" @click="hideError">Aceptar</b-button>
                    </b-col>
                </b-row>
                <b-row v-if="isSuccess" class="p-2">
                    <b-col class="text-center">
                        <b-icon-shield-check scale="2" variant="success" />
                        <h6 class="mt-3">
                            Se ha guardado correctamente el beneficiario final
                        </h6>
                        <b-button class="main-btn mt-2" @click="close">Aceptar</b-button>
                    </b-col>
                </b-row>

                <b-form-group class="mt-3">
                    <label v-show="!isError && !isSuccess" v-if="finalBeneficiaryType === 'complete'"
                        class="form-title">
                        Tipo de Beneficiario Final
                    </label>
                    <label v-show="!isError && !isSuccess" v-if="finalBeneficiaryType === 'pjbolsa'" class="form-title">
                        No se
                        requiere los datos del Beneficiario Final por la “EXCEPCIÓN CONTEMPLADA EN EL NUMERAL 3.1 DEL
                        ARTICULO 10 DE LA LEY 129 de 17 marzo de 2020”
                    </label>
                </b-form-group>

                <b-form-select class="form-select form-select-sm rounded-0" v-show="!isError && !isSuccess"
                    v-if="finalBeneficiaryType === 'complete'" v-model="selected" :options="options"
                    :disabled="finalBeneficiaryEdit != null">
                </b-form-select>

                <component v-show="!isError && !isSuccess" :legalEntityId="legalEntityId"
                    :finalBeneficiaryWho="parseFinalBeneficiaryWho(selected)" :is="addFinalBeneficiaryComponent"
                    :allowListedStockExchange="allowListedStockExchange" :finalBeneficiaryEdit="finalBeneficiaryEdit"
                    @error="showError" @success="showSuccess" />
            </div>
        </b-modal>
    </div>
</template>

<script>
import UpdateFinalBeneficiaryOperationForm
    from "@/components/add-final-beneficiary/types/UpdateFinalBeneficiaryOperationForm.vue";
import UpdateFinalBeneficiaryListedForm
    from "@/components/add-final-beneficiary/types/UpdateFinalBeneficiaryListedForm.vue";
import updateFinalBeneficiaryBolsaForm
    from "@/components/add-final-beneficiary/types/UpdateFinalBeneficiaryBolsaForm.vue";
import updateFinalBeneficiaryStateForm
    from "@/components/add-final-beneficiary/types/UpdateFinalBeneficiaryStateForm.vue";
import { mapActions } from "vuex";

export default {
    name: "add-final-beneficiary-modal",
    components: {
        UpdateFinalBeneficiaryOperationForm,
        UpdateFinalBeneficiaryListedForm,
        updateFinalBeneficiaryBolsaForm,
        updateFinalBeneficiaryStateForm
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        legalEntityId: {
            type: String,
            required: true,
        },
        finalBeneficiaryType: {
            type: String,
            default: "",
        },
        allowListedStockExchange: {
            type: Boolean,
            default: false,
        },
        finalBeneficiaryEdit: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isError: false,
            isSuccess: false,
            selected: 'naturalPerson',
            options: [
                { value: 'naturalPerson', text: 'Persona Natural' },
                { value: 'pjbolsa', text: 'Persona Jurídica que cotiza en bolsa' },
                { value: 'state', text: 'Estado' },
                { value: 'EEM', text: 'Entidad Estatal o Multilateral' }
            ],
            errorMessage: 'Se ha producido un error intentando guardar el beneficiario final, por favor revise los datos y vuelva a intentarlo.'
        };
    },
    computed: {
        addFinalBeneficiaryComponent() {
            if (this.finalBeneficiaryType === "complete") {
                switch (this.selected) {
                    case "naturalPerson":
                        return UpdateFinalBeneficiaryOperationForm
                    case "EEM":
                        return UpdateFinalBeneficiaryListedForm
                    case "pjbolsa":
                        return updateFinalBeneficiaryBolsaForm
                    case "state":
                        return updateFinalBeneficiaryStateForm
                }
            }
            else {
                switch (this.finalBeneficiaryType) {
                    case "EEM":
                        return UpdateFinalBeneficiaryListedForm;
                    case "pjbolsa":
                        return "";
                    case "state":
                        return updateFinalBeneficiaryStateForm
                }
            }

            return null;
        },
    },
    watch: {
        isOpen(isOpen) {
            // When open the modal, reset variables
            if (isOpen) {
                this.isError = false;
                this.isSuccess = false;

                if (this.finalBeneficiaryEdit != null) {
                    this.selectWho(this.finalBeneficiaryEdit.finalBeneficiaryWho);
                }
            }
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        close() {
            this.$emit("close");
        },
        showError(error) {
            console.log(error)
            let status = error?.response?.status;
            let response = error?.response?.data;

            if (status === 400) {
                if (typeof response === "object") {
                    let message = "";

                    if (response?.title === "One or more validation errors occurred." && response?.errors) {
                        var keyValidation = Object.keys(response.errors)[0];
                        if (keyValidation) {
                            if (keyValidation.includes("reasonGetFinalBeneficiaryStatus.status")) {
                                message = "El motivo es obligatorio.";
                            } else if (keyValidation.includes("birthDate")) {
                                message = "Fecha de nacimiento es requerido.";
                            } else if (keyValidation.includes("statusAcquireDate")) {
                                message = "Fecha en la que se adquiere la condición es requerido.";
                            } else {
                                message = "Error al consumir servicio.";
                            }
                        }
                    } else {
                        response.forEach((item) => {
                            const cadena = item.property;
                            const match = cadena.match(/\[(\d+)\]/);
                            const row = match ? parseInt(match[1]) : null;
                            let index = 0;
                            if (row != null) {
                                let label = '';
                                switch (this.getFinalBeneficiaries[row].finalBeneficiaryWho) {
                                    case 0:
                                        var pnItems = this.getFinalBeneficiaries.filter(item => item.finalBeneficiaryWho === 0);
                                        index = pnItems.findIndex(x => x === this.getFinalBeneficiaries[row]);
                                        label = 'Persona Natural';
                                        break;
                                    case 1:
                                        var pbItems = this.getFinalBeneficiaries.filter(item => item.finalBeneficiaryWho === 1);
                                        index = pbItems.findIndex(x => x === this.getFinalBeneficiaries[row]);
                                        label = 'Persona Jurídica que cotiza en bolsa';
                                        break;
                                    case 2:
                                        var eItems = this.getFinalBeneficiaries.filter(item => item.finalBeneficiaryWho === 2);
                                        index = eItems.findIndex(x => x === this.getFinalBeneficiaries[row]);
                                        label = 'Estado';
                                        break;
                                    case 3:
                                        var enItems = this.getFinalBeneficiaries.filter(item => item.finalBeneficiaryWho === 3);
                                        index = enItems.findIndex(x => x === this.getFinalBeneficiaries[row]);
                                        label = 'Entidad Estatal o Multilateral';
                                        break;
                                }
                                message += `<span>En la Fila ${index + 1} del Tipo de Beneficiario Final ${label}: </span>`;
                            }
                            let msg = this.$t(item.value);
                            message += `<p>${msg}</p>`;
                        });
                    }

                    this.openModal();
                    this.setModalInfo({
                        title: "Aviso",
                        message: message,
                    });
                    this.sending = false;
                    return;
                }
                else if (error?.response && error?.response?.data) {
                    this.errorMessage = this.$t(error.response.data);
                }
            }
            else if (error?.response && error?.response?.data) {
                this.errorMessage = this.$t(error.response.data);
            }
            this.isError = true;
        },
        hideError() {
            this.isError = false;
        },
        showSuccess() {
            this.isSuccess = true;
            this.$emit("refresh-data");
        },
        parseFinalBeneficiaryWho(option) {
            if (this.finalBeneficiaryType === "complete") {
                switch (option) {
                    case 'naturalPerson': return 0
                    case 'pjbolsa': return 1
                    case 'state': return 2
                    case 'EEM': return 3
                    default: return 4
                }
            } else {
                switch (this.finalBeneficiaryType) {
                    case "EEM":
                        return 3;
                    case "state":
                        return 2
                    case "pjbolsa":
                        return "";
                }
            }
        },
        selectWho(who) {
            switch (who) {
                case 0:
                    this.selected = 'naturalPerson';
                    return;
                case 1:
                    this.selected = 'pjbolsa';
                    return;
                case 2:
                    this.selected = 'state';
                    return;
                case 3:
                    this.selected = 'EEM';
                    return;
            }
        }
    },
};
</script>

<style scoped>
.modal-custom-title {
    position: relative;
    width: 100%;
}

.close-modal-button {
    position: absolute;
    top: 0px;
    right: 0px;
}
</style>