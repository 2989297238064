<template>
    <div>
        <b-row class="mb-5">
            <b-col class="mt-4">
                <div v-if="allowFinalBeneficiary || enabledCriteria" class="text-end mb-2">
                    <b-button class="main-btn" @click="openModal">
                        {{ $t("final-beneficiaries.add-final-beneficiary") }}
                    </b-button>
                </div>

                <b-row v-if="loading" class="justify-content-center my-2">
                    <b-spinner variant="primary"></b-spinner>
                </b-row>

                <component ref="finalBeneficiaries"
                    v-if="!loading"
                    :legalEntityId="legalEntity.id"
                    :is="finalBeneficiariesComponent"
                    :allowDelete="allowFinalBeneficiary || enabledCriteria"
                    @update="openUpdate"
                    @delete="openDelete"
                />
            </b-col>
        </b-row>

        <AddFinalBeneficiaryModal
            :legalEntityId="legalEntity.id"
            :finalBeneficiaryType="finalBeneficiaryType"
            :isOpen="openedModal"
            :allowListedStockExchange="allowListedStockExchange"
            :finalBeneficiaryEdit="finalBeneficiaryEdit"
            @close="closeModal"
            @refresh-data="refreshTable"
        />
        
        <DeleteFinalBeneficiaryModal
            :isOpen="openedDelete"
            :id="finalBeneficiaryId"
            :LegalEntityId="legalEntity.id"
            @close="closeDelete"
            @deleted="refreshTable"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import AddFinalBeneficiaryModal from "../add-final-beneficiary/AddFinalBeneficiaryModal.vue";
import DeleteFinalBeneficiaryModal from "../delete-final-beneficiary/DeleteFinalBeneficiaryModal.vue";
import SimpleFinalBeneficiaries from "./final-beneficiary-type/SimpleFinalBeneficiaries.vue";
import CompleteFinalBeneficiaries from "./final-beneficiary-type/CompleteFinalBeneficiaries.vue";

export default {
    name: "final-beneficiaries",
    components: {
        AddFinalBeneficiaryModal,
        DeleteFinalBeneficiaryModal
    },
    props: {
        legalEntity: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            openedModal: false,
            loading: false,
            finalBeneficiaryId: null,
            openedDelete: false,
            finalBeneficiaryEdit: null
        };
    },
    computed: {
        ...mapGetters("application", [
            "allowResidentAgentAdminFinalBeneficiaries",
            "getEnabledCriteria"
        ]),
        allowFinalBeneficiary() {
            return this.allowResidentAgentAdminFinalBeneficiaries;
        },
        enabledCriteria() {
            return this.getEnabledCriteria;
        },
        finalBeneficiariesComponent() {
            if (this.legalEntity === null) {
                return null;
            }
            switch (this.legalEntity.legalEntityType) {
                case 0:
                case 1:
                case 5:
                    return CompleteFinalBeneficiaries;
                case 2:
                case 3:
                case 4:
                    return SimpleFinalBeneficiaries;
            }
            return null;
        },
        finalBeneficiaryType() {
            if (this.legalEntity === null) {
                return null;
            }
            switch (this.legalEntity.legalEntityType) {
                case 0:
                case 1:
                case 5:
                    return "complete";
                case 2:
                    return "pjbolsa"
                case 3:
                    return "EEM"
                case 4:
                    return "state";
            }
            return null;
        },
        allowListedStockExchange() {
            if (this.legalEntity === null) {
                return false;
            }

            switch (this.legalEntity.legalEntityType) {
                case 0:
                case 1:
                case 4:
                    return true;
                case 2:
                case 3:
                case 5:
                    return false;
            }
            return false;
        },
    },
    methods: {
        openModal() {
            this.finalBeneficiaryEdit = null;
            this.openedModal = true;
        },
        closeModal() {
            this.openedModal = false;
            this.finalBeneficiaryEdit = null;
        },
        openUpdate(item) {
            this.finalBeneficiaryEdit = item;
            this.openedModal = true;
        },
        openDelete(id) {
            this.finalBeneficiaryId = id;
            this.openedDelete = true;
        },
        closeDelete() {
            this.finalBeneficiaryId = null;
            this.openedDelete = false;
        },
        refreshTable() {
            this.$refs.finalBeneficiaries.refreshData();
        },
    },
};
</script>