<template>
    <b-modal
        v-model="isOpen"
        content-class="rounded-0"
        dialog-class="long-modal"
        header-class="border-0"
        header-bg-variant="light"
        centered
        no-close-on-backdrop
        hide-footer
    >
        <template #modal-header>
            <div class="modal-custom-title">
                <h5 class="modal-title">
                    {{ $t("delete-final-beneficiary-modal.title") }}
                </h5>
                <b-button
                    size="sm"
                    class="close-modal-button"
                    variant="light"
                    @click="close"
                >
                    <b-icon-x scale="1.5"></b-icon-x>
                </b-button>
            </div>
        </template>
        <b-row v-if="isError" class="p-2">
            <b-col class="text-center">
                <b-icon-x-circle scale="2" variant="danger" />
                <h6 class="mt-3">
                    Se ha producido un error intentando borrar el beneficiario
                    final, recargue la ventana y vuelva a intentarlo.
                </h6>
                <b-button class="main-btn mt-2" @click="close"
                    >Aceptar</b-button
                >
            </b-col>
        </b-row>
        <b-row v-if="isSuccess" class="p-2">
            <b-col class="text-center">
                <b-icon-shield-check scale="2" variant="success" />
                <h6 class="mt-3">
                    Se ha borrado correctamente el beneficiario final
                </h6>
                <b-button class="main-btn mt-2" @click="close"
                    >Aceptar</b-button
                >
            </b-col>
        </b-row>
        <b-row v-if="!isError && !isSuccess">
            <b-col class="text-center">
                <h6 class="mt-3">
                    Se van a borrar los datos de este beneficiario final, esta
                    acción es irreversible, por favor confirme que quiere
                    borrarlos permanentemente.
                </h6>
                <b-overlay
                    :show="sending"
                    rounded
                    opacity="0.7"
                    spinner-small
                    spinner-variant="danger"
                    class="d-inline-block"
                >
                    <b-button
                        class="my-4"
                        variant="danger"
                        @click="deleteFinalBeneficiary"
                        >Borrar</b-button
                    >
                </b-overlay>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import moment from "moment/moment";
import { mapActions } from "vuex";

export default {
    name: "delete-final-beneficiary-modal",
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
        },
        LegalEntityId:{
            type: String,
        }
    },
    data() {
        return {
            listitems: [],
            sending: false,
            isError: false,
            isSuccess: false,
        };
    },
    watch: {
        isOpen(isOpen) {
            if (isOpen) {
                this.isError = false;
                this.isSuccess = false;
            }
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        parseDate(date) {
            if (date === null) {
                return "-";
            }
            return moment(date).format("DD/MM/YYYY");
        },
        async retrieveFinalBeneficiaries() {

            try {
                let response =
                    await RepositoryFactory.legalEntity.getFinalBeneficiaries({
                        id: this.LegalEntityId,
                    });
                let items = response.data;

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    item.creationDate = this.parseAndConvertDate(
                        item.creationDate
                    );
                    item.statusAcquireDate = this.parseDate(
                        item.statusAcquireDate
                    );
                    item.birthDate = this.parseDate(item.birthDate);
                    item.email = item.email ?? "-";
                    item.phone = item.phone ?? "-";
                }

                this.listitems = items;

            } catch (error) {
                window.console.log(error);
            }

        },
        async deleteFinalBeneficiary() {
            this.sending = true;
            try {
                await this.retrieveFinalBeneficiaries();
                if(this.listitems.length > 1){
                    await RepositoryFactory.legalEntity.deleteFinalBeneficiary({
                        id: this.id,
                    });
                    this.$emit("deleted");
                    this.isSuccess = true;
                }
                else {
                    this.close();
                    this.showHandleError();
                }
            } catch (error) {
                window.console.log(error);
                this.isError = true;
            }
            this.sending = false;
        },
        close() {
            this.$emit("close");
        },
        hideError() {
            this.isError = false;
        },
        showHandleError() {
            this.setModalInfo({
                title: "Error al eliminar beneficiario",
                message: "No se puede eliminar el beneficiario",
                subMessage: "debe de haber al menos un beneficiario para cada Persona Jurídica",
            });
            this.openModal();
        }
    },
};
</script>

<style scoped>
.modal-custom-title {
    position: relative;
    width: 100%;
}

.close-modal-button {
    position: absolute;
    top: 0px;
    right: 0px;
}
</style>