<template>
    <div class="mt-2">
        <b-form-group>
            <label class="form-title">Nombre completo de la PJ que cotiza en Bolsa de Valores*</label>
            <b-form-input v-model="finalBeneficiaryForm.entityCompleteName" size="sm" required class="rounded-0" />
        </b-form-group>

        <b-form-group class="mt-3">
            <label class="form-title">Dirección de la persona jurídica que cotiza en Bolsa de Valores*
            </label>
            <b-form-input v-model="finalBeneficiaryForm.direction" size="sm" required class="rounded-0" />
        </b-form-group>

        <b-form-group class="mt-3">
            <label class="form-title">País de constitución*</label>
            <select class="form-select form-select-sm rounded-0" v-model="finalBeneficiaryForm.country">
                <option v-for="country in countries" :key="country" :value="country">
                    {{ country }}
                </option>
            </select>
        </b-form-group>

        <b-form-group class="mt-3">
            <label class="form-title">
                Nombre completo de la Bolsa de Valores en que se encuentra listada la persona jurídica. *
            </label>
            <b-form-input v-model="finalBeneficiaryForm.stockExchangeCompleteName" size="sm" required
                class="rounded-0" />
        </b-form-group>

        <b-form-group class="mt-3">
            <label class="form-title">Email</label>
            <b-form-input placeholder="xxx@xxx.com" v-model="finalBeneficiaryForm.email" size="sm" class="rounded-0" />
        </b-form-group>

        <PhoneField ref="phoneField" v-model="finalBeneficiaryForm.phone" />
        <b-form-group class="mt-3">
            <label class="form-title">
                Jurisdicción donde opera la Bolsa de Valores*
            </label>
            <select class="form-select form-select-sm rounded-0" v-model="finalBeneficiaryForm.jurisdictionStockExchangeWhereListed
                ">
                <option :value="null"></option>
                <option v-for="country in countriesBolsa" :key="country" :value="country">
                    {{ country }}
                </option>
            </select>
        </b-form-group>
        <b-form-group class="mt-3">
            <label class="form-title">
                Nombre completo del representante legal o su equivalente*
            </label>
            <b-form-input v-model="finalBeneficiaryForm.legalRepresentantCompleteName" size="sm" required
                class="rounded-0" />
        </b-form-group>

        <ReasonGetFinalBeneficiaryStatusSelect ref="benefSelect"
            v-model="finalBeneficiaryForm.reasonGetFinalBeneficiaryStatus" />

        <b-overlay :show="sending" rounded opacity="0.7" spinner-small spinner-variant="primary" class="d-inline-block">
            <b-button class="my-4 main-btn" @click="addFinalBeneficiary" :disabled="!canSave">
                <span v-if="finalBeneficiaryEdit != null">Editar Beneficiario Final</span>
                <span v-else>Agregar Beneficiario Final</span>
            </b-button>
        </b-overlay>
    </div>
</template>

<script>
import PhoneField from "@/components/external-components/PhoneField.vue";
import { countries } from "@/config/countries";
import { countriesBolsa } from "@/config/countriesBolsa";
import ReasonGetFinalBeneficiaryStatusSelect from "@/components/widgets/ReasonGetFinalBeneficiaryStatusSelect.vue";
import { RepositoryFactory } from "@/services/api";

export default {
    name: "update-bolsa-final-beneficiary-form",
    components: {
        ReasonGetFinalBeneficiaryStatusSelect,
        PhoneField,
    },
    props: {
        legalEntityId: {
            type: String,
            required: true,
        },
        finalBeneficiaryWho: {
            type: Number,
            required: true
        },
        finalBeneficiaryEdit: {
            type: Object,
            default: null,
        },
    },
    mounted() {
        let item = this.finalBeneficiaryEdit;
        if (item) {
            this.notifyUpdateFinalBeneficiary(item);
        }
    },
    data() {
        return {
            countries: countries,
            countriesBolsa: countriesBolsa,
            finalBeneficiaryIndex: null,
            finalBeneficiaryForm: {
                entityCompleteName: "",
                direction: "",
                country: "",
                stockExchangeCompleteName: "",
                email: "",
                phone: "",
                jurisdictionStockExchangeWhereListed: "",
                legalRepresentantCompleteName: "",
                reasonGetFinalBeneficiaryStatus: { others: "", status: null },
                finalBeneficiaryType: 0,
                finalBeneficiaryWho: 1,
            },
            finalBeneficiaries: [],
            sending: false
        };
    },
    computed: {
        canSave() {
            const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            let form = this.finalBeneficiaryForm;
            var isEmail = form.email && form.email.trim() !== '' ? regex.test(form.email) : true;

            return form.entityCompleteName !== "" &&
                form.direction !== "" &&
                form.country !== "" &&
                isEmail &&
                form.legalRepresentantCompleteName !== "" &&
                form.reasonGetFinalBeneficiaryStatus !== null &&
                form.reasonGetFinalBeneficiaryStatus.status !== null &&
                form.jurisdictionStockExchangeWhereListed !== "" &&
                form.legalRepresentantCompleteName !== "";
        },
    },
    methods: {
        async addFinalBeneficiary() {
            try {
                this.sending = true;
                let finalBeneficiary = { ...this.finalBeneficiaryForm };
                finalBeneficiary.finalBeneficiaryWho = this.finalBeneficiaryWho;

                let payload = {
                    legalEntityId: this.legalEntityId,
                    finalBeneficiary: finalBeneficiary,
                };

                if (this.finalBeneficiaryEdit != null) {
                    payload.finalBeneficiaryId = this.finalBeneficiaryEdit.id;
                    await RepositoryFactory.legalEntity.updateFinalBeneficiary(
                        payload, this.finalBeneficiaryEdit.id
                    );
                } else {
                    await RepositoryFactory.legalEntity.addFinalBeneficiary(
                        payload
                    );
                }

                this.$emit("success");
            } catch (error) {
                window.console.log(error);
                this.$emit("error", error);
            }
            finally {
                this.sending = false;
            }
        },
        notifyUpdateFinalBeneficiary(item) {
            this.finalBeneficiaryForm = {
                entityCompleteName: item.entityCompleteName,
                direction: item.direction,
                jurisdictionStockExchangeWhereListed: item.jurisdictionStockExchangeWhereListed,
                country: item.country,
                legalRepresentantCompleteName: item.legalRepresentantCompleteName,
                phone: item.phone,
                email: item.email,
                stockExchangeCompleteName: item.stockExchangeCompleteName,
                reasonGetFinalBeneficiaryStatus: {
                    others: item.reasonGetFinalBeneficiaryStatus === null ? "" : item.reasonGetFinalBeneficiaryStatus.others
                    , status: item.reasonGetFinalBeneficiaryStatus === null ? "" : item.reasonGetFinalBeneficiaryStatus.status
                },
                finalBeneficiaryType: item.finalBeneficiaryType,
                finalBeneficiaryWho: item.finalBeneficiaryWho,
            };

            this.$refs.phoneField.loadPhone(item.phone);

            if (item.reasonGetFinalBeneficiaryStatus !== null)
                this.$refs.benefSelect.load(item.reasonGetFinalBeneficiaryStatus.status, item.reasonGetFinalBeneficiaryStatus.others);
        },
        deleteFinalBeneficiary(index) {
            this.finalBeneficiaries.splice(index, 1);
            if (this.finalBeneficiaryIndex === index) {
                this.finalBeneficiaryIndex = null;
            }
        },
        clearFields() {
            this.finalBeneficiaryForm = {
                entityCompleteName: "",
                direction: "",
                country: "",
                stockExchangeCompleteName: "",
                email: "",
                phone: "",
                jurisdictionStockExchangeWhereListed: "",
                legalRepresentantCompleteName: "",
                reasonGetFinalBeneficiaryStatus: { others: "", status: null },
                finalBeneficiaryType: 0,
                finalBeneficiaryWho: 1,
            };
            this.$refs.phoneField.clear();
            this.$refs.benefSelect.clear();
        },
    },
};
</script>