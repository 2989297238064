<template>
    <b-row class="mt-2">
        <b-col>
            <b-form-group>
                <label class="form-title">
                    {{ $t("general.society-type") }}
                </label>
                <select disabled class="form-select form-select-sm rounded-0" v-model="legalPersonForm.societyType">
                    <option value="SA">
                        {{ $t("society-types.sociedad anonima") }}
                    </option>
                    <option value="FU">
                        {{ $t("society-types.fundacion de interes propio") }}
                    </option>
                    <option value="LI">
                        {{ $t("society-types.sociedad limitada") }}
                    </option>
                    <option value="EX">
                        {{ $t("society-types.sociedad extranjera") }}
                    </option>
                    <option value="CM">
                        {{ $t("society-types.sociedad en comandita") }}
                    </option>
                    <option value="S.EP">
                        {{ $t("society-types.sociedad de emprendimiento") }}
                    </option>
                </select>
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title" label-for="name-input">
                    Número de ficha o folio*
                </label>
                <b-form-input id="name-input" v-model="legalPersonForm.folioNumber" size="sm" required class="rounded-0"
                    disabled />
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title" label-for="name-input">
                    Nombre completo*
                </label>
                <b-icon-question-circle-fill class="ms-2 card-title-color"
                    title="Como aparece en el Registro Público" />
                <b-form-input id="name-input" v-model="legalPersonForm.name" size="sm" required class="rounded-0" />
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title">RUC*</label>
                <b-form-input v-model="legalPersonForm.ruc" size="sm" required class="rounded-0" />
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title" label-for="name-input">
                    Dirección*
                </label>
                <b-form-input id="name-input" v-model="legalPersonForm.direction" size="sm" required
                    class="rounded-0" />
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title" label-for="name-input">
                    País de constitución*
                </label>
                <select class="form-select form-select-sm rounded-0" v-model="legalPersonForm.countryOfConstitution">
                    <option v-for="country in countries" :key="country" :value="country">
                        {{ country }}
                    </option>
                </select>
            </b-form-group>
            <b-form-group class="mt-3">
                <label class="form-title" label-for="name-input">
                    Bolsas de Valores donde se encuentra listada (Listado de la SVP)*
                </label>
                <b-form-input id="name-input" v-model="legalPersonForm.stockExchangeWhereIsPublished" size="sm" required
                    class="rounded-0" />
            </b-form-group>

            <b-form-group class="mt-3">
                <label class="form-title">
                    Jurisdicción de Bolsa de Valores donde es pública *
                    <b-icon-question-circle-fill class="ms-2 card-title-color"
                        title="“Fundamento legal: artículo 10 del Decreto Ejecutivo N°13 de 25 de Marzo del 2022, inciso (iii), Literal a" />
                </label>
                <b-input-group>
                    <select class="form-select form-select-sm rounded-0" v-model="jurisdictionselect">
                        <option value=" "></option>
                        <option v-for="country in countriesBolsa" :key="country" :value="country">
                            {{ country }}
                        </option>
                    </select>
                    <b-input-group-append>
                        <b-button v-b-tooltip.hover title="Salvar" @click="addJuridiction" class="main-btn">
                            <b-icon-plus scale="1.5" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>

            <listJuridictions :jurisdictions="legalPersonForm.jurisdictions" @delete="deleteJuridiction" />

            <label class="form-title mt-3">
                Servicio de accionistas / directores nominales o apoderados
            </label>
            <b-td style="border: 1px"></b-td>
            <b-row>
                <b-col cols="9">
                    <b-form-group>
                        <label class="form-title">
                            ¿Servicio de accionista Nominal?
                        </label>
                    </b-form-group>
                    <b-form-group>
                        <label class="form-title">
                            ¿Servicio de director Nominal?
                        </label>
                    </b-form-group>
                    <b-form-group>
                        <label class="form-title">
                            ¿Servicio de apoderado?
                        </label>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-row>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.nominalShareholderService"
                                    :aria-describedby="ariaDescribedby" name="radio1" :value="true"> Si</b-form-radio>
                            </b-col>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.nominalShareholderService"
                                    :aria-describedby="ariaDescribedby" name="radio1" :value="false"> No</b-form-radio>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-row>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.nominalDirectorService"
                                    :aria-describedby="ariaDescribedby" name="radio2" :value="true"> Si</b-form-radio>
                            </b-col>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.nominalDirectorService"
                                    :aria-describedby="ariaDescribedby" name="radio2" :value="false"> No</b-form-radio>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-row>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.proxyService" :aria-describedby="ariaDescribedby"
                                    name="radio3" :value="true"> Si</b-form-radio>
                            </b-col>
                            <b-col cols="6">
                                <b-form-radio v-model="legalPersonForm.proxyService" :aria-describedby="ariaDescribedby"
                                    name="radio3" :value="false"> No</b-form-radio>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import { countries } from "@/config/countries";
import { countriesBolsa } from "@/config/countriesBolsa";
import listJuridictions from "@/components/legal-entities-types/list/ListJuridictions.vue";
import { parseSocietyType } from "@/utils/parse";

export default {
    name: "update-legal-person-listed-form",
    components: {
        listJuridictions
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            countries: countries,
            countriesBolsa: countriesBolsa,
            legalPersonForm: {},
            jurisdictionselect: null,
        };
    },
    mounted() {
        let legalEntity = JSON.parse(JSON.stringify(this.value));
        this.legalPersonForm = {
            societyType: parseSocietyType(legalEntity.societyType),
            id: legalEntity.id,
            folioNumber: legalEntity.folioNumber,
            name: legalEntity.name,
            ruc: legalEntity.ruc,
            direction: legalEntity.direction,
            countryOfConstitution: legalEntity.countryOfConstitution,
            stockExchangeWhereIsPublished: legalEntity.stockExchangeWhereIsPublished,
            shareholder: legalEntity.shareholder,
            jurisdictions: legalEntity.jurisdictions,
            nominalShareholderService: legalEntity.nominalShareholderService,
            nominalDirectorService: legalEntity.nominalDirectorService,
            proxyService: legalEntity.proxyService,
        };
    },
    watch: {
        legalPersonForm: {
            deep: true,
            handler() {
                this.$emit("input", this.legalPersonForm);
            },
        },
    },
    methods: {
        addJuridiction() {
            if ((typeof this.jurisdictionselect != "undefined" && this.jurisdictionselect)) {
                let juridictObj = { jurisdiction: this.jurisdictionselect };
                this.legalPersonForm.jurisdictions.push(juridictObj);
                this.jurisdictionselect = null;
            }
        },
        deleteJuridiction(index) {
            this.legalPersonForm.jurisdictions.splice(index, 1);
            if (this.juridictionIndex === index) {
                this.juridictionIndex = null;
            }
        },
    }
};
</script>