<template>
    <div>
        <b-button class="main-btn me-2" size="sm" @click="open">
            <b-icon-trash />
        </b-button>
        <b-modal
            v-model="show"
            content-class="rounded-0"
            dialog-class="long-modal"
            header-class="border-0"
            header-bg-variant="light"
            :title="$t('disable-legal-entity-modal.title')"
            hide-header-close
            centered
            no-close-on-backdrop
            hide-footer
        >
            <b-row v-if="step === 0">
                <b-col>
                    <b-form-group>
                        <label class="form-title"
                            >Razon de la desactivación*</label
                        >
                        <DisableReasonSelect v-model="reason" @input="changeReason" />

                        <label class="form-title mt-3" v-if="reason === 100">Motivo</label>
                        <OtherReasonDisableSelect v-if="reason === 100" v-model="other" @input="changeOther" />
                        
                        <b-form-group v-if="reason === 100" class="mt-3">
                            <label class="form-title">Indicar motivo*</label>
                            <b-form-textarea
                                v-model="details"
                                size="sm"
                                required
                                rows="4"
                                no-resize
                                class="rounded-0"
                                :disabled="other != null"
                            />
                        </b-form-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="step === 1">
                <b-col>
                    <div class="text-center">
                        <p class="form-title mt-2">
                            {{ $t("disable-legal-entity-modal.body") }}
                        </p>
                    </div>
                </b-col>
            </b-row>
            <b-row class="m-3 text-end">
                <b-col>
                    <b-button
                        size="sm"
                        variant="danger"
                        class="px-3 ms-4"
                        @click="close"
                    >
                        Cerrar
                    </b-button>
                    <b-button
                        v-if="step === 0"
                        size="sm"
                        class="px-3 ms-4"
                        :disabled="!enabledNext"
                        @click="next"
                    >
                        Continuar
                    </b-button>
                    <b-overlay
                        v-if="step === 1"
                        :show="sending"
                        opacity="0.7"
                        spinner-small
                        spinner-variant="primary"
                        class="d-inline-block ms-2"
                    >
                        <b-button size="sm" class="main-btn" @click="disable">
                            Aceptar
                        </b-button>
                    </b-overlay>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions } from "vuex";

import DisableReasonSelect from "@/components/widgets/DisableReasonSelect.vue";
import OtherReasonDisableSelect from "@/components/widgets/OtherReasonDisableSelect.vue";

export default {
    components: {
        DisableReasonSelect,
        OtherReasonDisableSelect,
    },
    name: "disable-legal-entity-modal",
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            step: 0,
            reason: null,
            other: null,
            details: "",
            sending: false,
            show: false,
        };
    },
    computed: {
        enabledNext() {
            if (this.reason === null) {
                return false;
            }
            if (this.reason === 100 && this.details === "") {
                return false;
            }
            return true;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async disable() {
            this.sending = true;
            try {
                let payload = {
                    id: this.id,
                    disableReason: this.reason,
                    details: this.details,
                };
                await RepositoryFactory.legalEntity.disable(payload);
                this.setModalInfo({
                    title: this.$t("disable-legal-entity-modal.success.title"),
                    message: this.$t(
                        "disable-legal-entity-modal.success.message"
                    ),
                    routeName: "legal-entities",
                });
            } catch {
                this.setModalInfo({
                    title: this.$t("disable-legal-entity-modal.error.title"),
                    message: this.$t(
                        "disable-legal-entity-modal.error.message"
                    ),
                });
            } finally {
                this.close();
                this.openModal();
                this.sending = false;
            }
        },
        next() {
            this.step++;
        },
        open() {
            this.step = 0;

            this.show = true;
        },
        close() {
            this.show = false;
        },
        changeReason() {
            this.other = null;
            this.details = "";
        },
        changeOther() {
            this.details = "";

            if (this.other != null) {
                this.details = this.other;
            }
        }
    },
};
</script>